<script>
import { defineComponent } from "vue";
import { orderComputed, ordersMehtods } from "@/state/helpers";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import OrdersApi from "@/api/orders/orders_api";
import Swal from "sweetalert2";
import SelectShipmentProduct from "./SelectShipmentProduct.vue";

export default defineComponent({
  name: "Step3",
  emits: ["go-next-step", "go-previous-step"],
  components: { Multiselect, SelectShipmentProduct },
  data() {
    return {
      multiselect: {
        counterparties: [],
        categories: [],
        territories: [],
      },
    };
  },
  computed: {
    ...orderComputed,
    counterparties: {
      get() {
        return this.$store ? this.$store.state.orders.counterparties : [];
      },
      set(value) {
        this.addCounterparty(value);
      },
    },
    is_pre_order: {
      get() {
        return this.$store ? this.$store.state.orders.is_pre_order : false;
      },
      set(value) {
        this.setIsPreOrder(value);
      },
    },

    pre_order: {
      get() {
        return this.$store ? this.$store.state.orders.pre_order : {};
      },
      set(value) {
        this.setPreOrder(value);
      },
    },

    multiselect_counterparties() {
      if (this.pre_order && this.pre_order.type === "code_order") {
        return this.multiselect.counterparties.filter(
          (c) => c.is_used_for_code
        );
      }
      return this.multiselect.counterparties;
    },

    hasDuplicateCounterparties() {
      return this.counterparties.some((counterparty, index) => {
        const duplicateIndex = this.counterparties
          .filter((c) => c.counterparty !== null)
          .findIndex(
            (c, i) => i !== index && c.counterparty.value === counterparty.value
          );
        return duplicateIndex !== -1;
      });
    },
    counterpartiesWaitingForDelete() {
      return this.counterparties.filter((c) => c.delete_is_confirmed);
    },
    notFilledCounterparties() {
      if (this.pre_order && this.pre_order.type === "code_order") {
        return this.counterparties.filter(
          (c) =>
            c.counterparty === null ||
            c.categories.length === 0 ||
            c.territories.length === 0
        );
      } else {
        return this.counterparties.filter(
          (c) => c.counterparty === null || c.categories.length === 0
        );
      }
    },

    canAddNewCounterparty() {
      if (this.pre_order && this.pre_order.type === "code_order") {
        return (
          this.counterpartiesWaitingForDelete.length === 0 &&
          this.hasDuplicateCounterparties === false &&
          this.notFilledCounterparties.length === 0 &&
          this.pre_order.territories.length > this.totalTerritoriesSelected
        );
      } else {
        return (
          this.counterpartiesWaitingForDelete.length === 0 &&
          this.hasDuplicateCounterparties === false &&
          this.notFilledCounterparties.length === 0
        );
      }
    },

    cangoNextStep() {
      if (this.pre_order && this.pre_order.type === "code_order") {
        return (
          this.counterpartiesWaitingForDelete.length === 0 &&
          this.hasDuplicateCounterparties === false &&
          this.notFilledCounterparties.length === 0 &&
          this.pre_order.territories.length === this.totalTerritoriesSelected
        );
      } else {
        return (
          this.counterpartiesWaitingForDelete.length === 0 &&
          this.hasDuplicateCounterparties === false &&
          this.notFilledCounterparties.length === 0
        );
      }
    },

    totalTerritoriesSelected() {
      if (this.pre_order && this.pre_order.type === "code_order") {
        if (!this.counterparties) return 0;
        return this.counterparties.reduce(
          (total, currentItem) => total + currentItem.territories.length,
          0
        );
      } else {
        return 0;
      }
    },

    canDeleteCounterparty() {
      return (
        this.counterpartiesWaitingForDelete.length === 0 &&
        this.counterparties.length > 1
      );
    },
  },
  methods: {
    ...ordersMehtods,

    async getCounterparties() {
      let api = new OrdersApi();
      let data = await api.getCounterpartyList();
      this.multiselect.counterparties = data.results.map((c) => {
        return this.pre_order && this.pre_order.type === "code_order"
          ? {
              value: c.id,
              label: c.name,
              is_used_for_code: c.is_used_for_code,
              territories: [],
            }
          : {
              value: c.id,
              label: c.name,
              is_used_for_code: c.is_used_for_code,
            };
      });
    },
    async getCategories() {
      let api = new OrdersApi();
      let data = await api.getCategoryList();
      this.multiselect.categories = data.results.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
    },
    async refreshCounterpartyCategory() {
      await this.getCounterparties();
      await this.getCategories();
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      });
      await Toast.fire({
        icon: "success",
        title: "Counterparty and category list refreshed successfully",
      });
    },

    async getTerritories() {
      if (this.pre_order && this.pre_order.type === "code_order") {
        this.multiselect.territories = this.pre_order.territories.map((c) => {
          return {
            value: c.id,
            label: c.name,
          };
        });
      } else {
        return [];
      }
    },

    addNewCounterparty() {
      this.addCounterparty(
        this.pre_order && this.pre_order.type === "code_order"
          ? {
              counterparty: null,
              categories: [],
              territories: [],
              delete_is_confirmed: false,
            }
          : {
              counterparty: null,
              categories: [],
              delete_is_confirmed: false,
            }
      );
    },
    deleteCounterparty(counterparty, index) {
      this.removeCounterparty(index);
    },

    onCounterpartyChange(event, counterparty, index) {
      this.$store.state.orders.container_types.forEach((container_type) => {
        container_type.counterparties[index].counterparty = { ...event };
      });
    },

    onCounterpartyTerritoryChange(event, counterparty, index) {
      this.$store.state.orders.container_types.forEach((container_type) => {
        container_type.counterparties[index].territories = [...event];
      });
    },

    onCounterpartyCategoryChange(event, counterparty, index) {
      let newCategories = [...event];
      this.$store.state.orders.container_types.forEach((container_type) => {
        const difference = newCategories.filter(
          (item1) =>
            !container_type.counterparties[index].categories.some(
              (item2) => item2.value === item1.value
            )
        );
        if (difference.length > 0) {
          container_type.counterparties[index].categories.push({
            ...difference[0],
            price: "",
          });
        } else {
          let d2 = container_type.counterparties[index].categories.filter(
            (item1) =>
              !newCategories.some((item2) => item2.value === item1.value)
          );
          d2.forEach((d) => {
            container_type.counterparties[index].categories =
              container_type.counterparties[index].categories.filter(
                (category) => {
                  return category.value !== d.value;
                }
              );
          });
        }
      });
    },

    goNextStep() {
      if (this.cangoNextStep) {
        this.$emit("go-next-step");
        return true;
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: "Please fill all fields",
        });
        return false;
      }
    },
    goPreviousStep() {
      this.$emit("go-previous-step");
    },

    counterpartyIsAlreadySelected(counterparty) {
      if (counterparty === null) return false;
      return (
        this.counterparties
          .filter((c) => c.counterparty !== null)
          .filter((c) => c.counterparty.value === counterparty.value).length > 1
      );
    },
  },

  async mounted() {
    await this.getCounterparties();
    await this.getCategories();
    await this.getTerritories();
  },
});
</script>

<template>
  <div class="d-flex">
    <div>
      <h5 class="mb-1">Counterparty Information</h5>
      <p class="text-muted mb-4">
        Please fill all information below<br /><br />
      </p>
    </div>
  </div>

  <form @submit.prevent="goNextStep()">
    <div class="mb-5">
      <div class="row gy-3 justify-content-between align-items-center mb-3">
        <div class="col-12 col-lg-4">
          <h4 class="mb-0">
            Selected Counterparties
            <span
              v-b-tooltip.hover
              title="Click to refresh the counterparty and category options"
              @click="refreshCounterpartyCategory()"
              class="cursor-pointer"
            >
              <i class="mdi mdi-refresh"></i>
            </span>
          </h4>
        </div>
        <div class="col-12 col-lg-6">
          <div
            v-if="canAddNewCounterparty && notFilledCounterparties.length === 0"
            class="d-flex flex-column flex-lg-row justify-content-end align-items-center gap-3"
          >
            <SelectShipmentProduct />
            <b-button
              style="min-width: 200px"
              type="button"
              variant="outline-success"
              @click="addNewCounterparty()"
            >
              <i class="mdi mdi-plus me-1"></i>
              Add Counterparty
            </b-button>
          </div>
          <div
            v-else
            v-b-tooltip.hover
            class="d-flex flex-column flex-lg-row justify-content-end align-items-center gap-3"
            title="Please complete or remove items with missing counterparty name or category !"
          >
            <SelectShipmentProduct disabled="true" />
            <b-button
              style="min-width: 200px"
              disabled
              type="button"
              variant="outline-success"
            >
              <i class="mdi mdi-plus me-1"></i>
              Add Counterparty
            </b-button>
          </div>
        </div>
      </div>
      <b-row class="gy-3">
        <b-col
          v-for="(counterparty, index) in counterparties"
          :key="counterparty.counterparty"
          md="6"
          lg="4"
        >
          <div
            class="border rounded-3 p-3 pb-1"
            :class="{
              'border-secondary': !counterparty.delete_is_confirmed,
              'border-danger':
                counterparty.delete_is_confirmed ||
                counterpartyIsAlreadySelected(counterparty.counterparty),
            }"
          >
            <div class="mb-3">
              <label class="form-label mb-3">
                <span
                  class="badge me-1 align-top"
                  :class="{
                    'badge-gradient-secondary':
                      !counterparty.delete_is_confirmed,
                    'badge-gradient-danger':
                      counterparty.delete_is_confirmed ||
                      counterpartyIsAlreadySelected(counterparty.counterparty),
                  }"
                >
                  {{ index + 1 }}
                </span>
                Counterparty & Categories
                <span class="text-danger">*</span>
              </label>
              <div>
                <Multiselect
                  :object="true"
                  :searchable="true"
                  :options="multiselect_counterparties"
                  :disabled="counterparty.delete_is_confirmed"
                  placeholder="Select counterparty"
                  v-model="counterparty.counterparty"
                  @input="onCounterpartyChange($event, counterparty, index)"
                />
              </div>
            </div>
            <div class="mb-3">
              <Multiselect
                :object="true"
                :searchable="true"
                class="p-2"
                :options="multiselect.categories"
                placeholder="Select categories"
                :disabled="counterparty.delete_is_confirmed"
                :closeOnSelect="false"
                mode="tags"
                :hideSelected="false"
                v-model="counterparty.categories"
                @input="
                  onCounterpartyCategoryChange($event, counterparty, index)
                "
              >
              </Multiselect>
            </div>
            <div
              class="mb-3"
              v-if="pre_order && pre_order.type === 'code_order'"
            >
              <Multiselect
                :object="true"
                :searchable="true"
                class="p-2"
                :options="multiselect.territories"
                placeholder="Select territories"
                :disabled="counterparty.delete_is_confirmed"
                :closeOnSelect="false"
                mode="tags"
                :hideSelected="false"
                v-model="counterparty.territories"
                @input="
                  onCounterpartyTerritoryChange($event, counterparty, index)
                "
              >
              </Multiselect>
            </div>
            <div class="text-end mb-2">
              <b-button-group
                v-if="counterparty.delete_is_confirmed"
                role="group"
                aria-label="Basic example"
              >
                <b-button
                  size="sm"
                  variant="danger"
                  @click="deleteCounterparty(counterparty, index)"
                >
                  Confirm & Remove
                </b-button>
                <b-button
                  size="sm"
                  variant="light"
                  @click="counterparty.delete_is_confirmed = false"
                >
                  Cancel
                </b-button>
              </b-button-group>

              <b-button
                v-else
                :disabled="!canDeleteCounterparty"
                @click="counterparty.delete_is_confirmed = true"
                variant="outline-danger"
                size="sm"
              >
                Remove
              </b-button>
            </div>
            <h6
              class="text-danger mt-3 fs-15"
              v-if="counterpartyIsAlreadySelected(counterparty.counterparty)"
            >
              <span class="badge bg-danger"> Duplicate counterparties</span>
            </h6>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="d-flex align-items-start gap-3 mt-4">
      <b-button
        @click="goPreviousStep()"
        type="button"
        variant="light"
        class="btn-label"
      >
        <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i
        >Back to Order Info (2)
      </b-button>
      <b-button
        :disabled="!cangoNextStep"
        type="submit"
        variant="success"
        class="btn-label right ms-auto"
      >
        <i class="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>Next
      </b-button>
    </div>
  </form>
</template>

<style scoped></style>
