<script>
import {defineComponent} from 'vue'
import axios from "axios";

export default defineComponent({
  name: "ShipmentProductsList",
  data() {
    return {
      shipmentProducts: [],
      details: [],

      current_product: {},
      loading: false
    }
  },
  methods: {
    async getShipmentProducts() {
      try {
        let response = await axios.get('/counterparty/product/list/')
        this.shipmentProducts = response.data.results
      } catch {
        this.shipmentProducts = []
      }
    },
    async showProductInfo(product) {
      if (this.current_product.id === product.id) return
      this.current_product = product
      this.loading = true
      this.details = []
      let shipment_product = {
        id: product.id,
        name: product.name,
        container_type: product.container_type,
      }
      this.details = await this.getShipmentDetails(shipment_product)
      this.loading = false
    },
    async getShipmentDetails(shipment_product) {
      try {
        let response = await axios.get(`/counterparty/product/${shipment_product.id}/`)
        return response.data.shipment_details
      } catch {
        return []
      }
    },
    showIt() {
      alert('hey')
    }
  },
  created() {
    this.getShipmentProducts()
  }
})
</script>

<template>
  <b-card no-body>
    <b-card-body class="mb-0 pb-0">
      <h5 class="card-title mb-3">Shipment Products List</h5>
    </b-card-body>
    <b-card-body class="py-0 px-0">
      <b-row gutter-y="1">
        <b-col sm="12">
          <b-accordion flush :id="`accordionProduct`">
            <b-accordion-item
                v-for="product in shipmentProducts" :key="`shipment_product_` + product.id"
                @click="showProductInfo(product)"
            >

              <template v-slot:title>
                <span class="badge badge-gradient-secondary me-3">{{ product.container_type }}</span>
                {{ product.name }}
              </template>

              <template v-if="loading">
                <div class="d-flex justify-content-center">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </template>

              <template v-else>
                <div v-if="details.length > 0" class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <thead>
                    <tr>
                      <th scope="col">Counterparty</th>
                      <th scope="col">Category</th>
                      <th scope="col">Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="detail in details" :key="`detail` + detail">
                      <tr v-for="category in detail.categories" :key="`category` + category">
                        <th scope="row"><a class="fw-semibold">{{ detail.counterparty_name }}</a></th>
                        <td>{{ category.category_name }}</td>
                        <td>${{ category.price ? category.price : 0 }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
                <span v-else>
                  No shipment details
                </span>
              </template>
            </b-accordion-item>
          </b-accordion>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>