<script>
import Create from "@/views/pages/orders/container/create/Create.vue"
import AutoComplete from "@/views/pages/orders/container/create/AutoComplete.vue"

export default {
  data() {
    return {
      autocompleted: false
    }
  },
  components: {
    Create,
    AutoComplete
  },
  mounted() {
    setTimeout(()=> {
        this.$refs.autocomplete.autocompleteOrder(this.$route.params.id)
    }, 1000)
  }
}
</script>

<template>
  <template v-if="autocompleted">
      <Create perform="update"/>
  </template>
  <div v-else class="text-center">
    <b-spinner></b-spinner>
  </div>
  <div class="d-none">
    <AutoComplete perform="update" ref="autocomplete" @autocompleted="autocompleted = $event || true"/>
  </div>
</template>
