<script>
import { defineComponent } from 'vue'
import { orderComputed, ordersMehtods } from "@/state/helpers"
import SelectCompany from "@/components/custom/SelectCompany.vue";
import ValidationError from "@/views/pages/orders/container/ValidationError.vue";
import SelectProduct from "@/components/custom/SelectProduct.vue";
import Swal from "sweetalert2";
import axios from 'axios';

export default defineComponent({
  name: "Step2",
  emits: ['go-next-step', 'go-previous-step'],
  components: { SelectProduct, ValidationError, SelectCompany },
  data() {
    return {
      step2_is_submitted: false,
    }
  },
  props: {
    perform: {
      type: String,
      required: false,
      default: 'create'
    }
  },
  computed: {
    ...orderComputed,
    border_crossing: {
      get() {
        return this.$store ? this.$store.state.orders.border_crossing : ''
      },
      set(value) {
        this.setBorderCrossing(value)
      }
    },
    conditions_of_carriage: {
      get() {
        return this.$store ? this.$store.state.orders.conditions_of_carriage : ''
      },
      set(value) {
        this.setConditionsOfCarriage(value)
      }
    },
    rolling_stock: {
      get() {
        return this.$store ? this.$store.state.orders.rolling_stock : ''
      },
      set(value) {
        this.setRollingStock(value)
      }
    },
    departure: {
      get() {
        return this.$store ? this.$store.state.orders.departure : ''
      },
      set(value) {
        this.setDeparture(value)
      }
    },
    destination: {
      get() {
        return this.$store ? this.$store.state.orders.destination : ''
      },
      set(value) {
        this.setDestination(value)
      }
    },
    departure_id: {
      get() {
        return this.$store ? this.$store.state.orders.departure_id : ''
      },
      set(value) {
        this.setDepartureId(value)
      }
    },
    destination_id: {
      get() {
        return this.$store ? this.$store.state.orders.destination_id : ''
      },
      set(value) {
        this.setDestinationId(value)
      }
    },
    product_id: {
      get() {
        return this.$store ? this.$store.state.orders.product_id : ''
      },
      set(value) {
        this.setProductId(value)
      }
    },
    company_id: {
      get() {
        return this.$store ? this.$store.state.orders.company_id : ''
      },
      set(value) {
        this.setCompanyId(value)
      }
    },
    comment: {
      get() {
        return this.$store ? this.$store.state.orders.comment : ''
      },
      set(value) {
        this.setComment(value)
      }
    },
    consignee: {
      get() {
        return this.$store ? this.$store.state.orders.consignee : ''
      },
    },
    shipper: {
      get() {
        return this.$store ? this.$store.state.orders.shipper : ''
      },
    },
    date: {
      get() {
        return this.$store ? this.$store.state.orders.date : ''
      },
    },
    lot_number: {
      get() {
        return this.$store ? this.$store.state.orders.lot_number : ''
      },
    },
    payment_status: {
      get() {
        return this.$store ? this.$store.state.orders.payment_status : ''
      },
    },
    shipment_status: {
      get() {
        return this.$store ? this.$store.state.orders.shipment_status : ''
      },
    },
    order_type: {
      get() {
        return this.$store ? this.$store.state.orders.order_type : ''
      },
    },
    autocomplete: {
      get() {
        return this.$store ? this.$store.state.orders.autocomplete : ''
      },
      set(value) {
        this.setAutoComplete(value)
      }
    },
    is_pre_order: {
      get() {
        return this.$store ? this.$store.state.orders.pre_order && this.$store.state.orders.pre_order.type === 'code_order' : false
      },
      set(value) {
        this.setIsPreOrder(value)
      }
    },
  },
  methods: {
    ...ordersMehtods,
    onProductSelected(product) {
      this.product_id = product ? product.value : null
    },
    onCompanySelect(company) {
      this.company_id = company ? company.value : null
    },
    validate() {
      this.step2_is_submitted = true
      return this.conditions_of_carriage &&
        this.rolling_stock &&
        this.departure_id &&
        this.company_id &&
        this.product_id &&
        this.destination_id
    },
    async goNextStep() {
      if (this.validate()) {
        if (this.perform === "update") {
          await this.updateOrder(this.$route.params.id)
        } else {
          this.$emit('go-next-step')
          return true
        }
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'warning',
          title: 'Please fill all fields',
        })
        return false
      }
    },
    goPreviousStep() {
      this.$emit('go-previous-step')
    },

    async updateOrder(order_number) {
      if (!order_number) return
      try {
        await axios.put(`/container_order/list/${order_number}/edit/`, {
          product_id: this.product_id,
          sending_type: this.sending_type,
          order: {
            border_crossing: this.border_crossing,
            comment: this.comment,
            company_id: this.company_id,
            conditions_of_carriage: this.conditions_of_carriage,
            consignee: this.consignee,
            date: this.date,
            departure_country: this.departure,
            destination_country: this.destination,
            departure_id: this.departure_id,
            destination_id: this.destination_id,
            lot_number: this.lot_number,
            payment_status: this.payment_status,
            position: this.position,
            rolling_stock: this.rolling_stock,
            shipment_status: this.shipment_status,
            shipper: this.shipper,
            type: this.order_type,
          }
        })
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Order succussfully updated'
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please, check you have filled all the fields',
        })
      }
      return
    }
  }
})
</script>

<template>
  <div>
    <h5 class="mb-1">Order Information (2)</h5>
    <p class="text-muted mb-4">
      Please fill all information below
    </p>
  </div>

  <form @submit.prevent="goNextStep()">
    <b-row gutterY="3" class="mb-3">
      <b-col lg="4">
        <label class="form-label">
          Border Crossing
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="border_crossing" type="text" class="form-control"
          placeholder="Enter border crossing" />
        <input v-if="is_pre_order" :value="border_crossing" type="text" class="form-control"
          placeholder="Enter border crossing" disabled />
      </b-col>

      <b-col lg="4">
        <label class="form-label">
          Conditions of Carriage
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="conditions_of_carriage" type="text" class="form-control"
          placeholder="Conditions of carriage" />
        <input v-if="is_pre_order" :value="conditions_of_carriage" type="text" class="form-control"
          placeholder="Conditions of carriage" disabled />
        <ValidationError :show="step2_is_submitted" :value="conditions_of_carriage" />
      </b-col>
      <b-col lg="4">
        <label class="form-label">
          Rolling Stock
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="rolling_stock" type="text" class="form-control"
          placeholder="Rolling stock" />
        <input v-else-if="is_pre_order" :value="rolling_stock" type="text" class="form-control"
          placeholder="Rolling stock" disabled />
        <ValidationError :show="step2_is_submitted" :value="rolling_stock" />
      </b-col>
    </b-row>

    <b-row gutterY="3" class="mb-3">
      <b-col lg="4">
        <label class="form-label">
          Departure
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="departure" type="text" class="form-control" placeholder="Enter departure" />
        <input v-else-if="is_pre_order" disabled :value="departure" type="text" class="form-control"
          placeholder="Enter departure" />
        <ValidationError :show="step2_is_submitted" :value="departure" />
      </b-col>
      <b-col lg="4">
        <label class="form-label">
          Destination
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="destination" type="text" class="form-control"
          placeholder="Enter destination" />
        <input v-else-if="is_pre_order" disabled :value="destination" type="text" class="form-control"
          placeholder="Enter destination" />

        <ValidationError :show="step2_is_submitted" :value="destination" />
      </b-col>
      <b-col lg="4">
        <label class="form-label">
          Company
          <span class="text-danger">*</span>
        </label>
        <template v-if="autocomplete.companies">
          <SelectCompany :disabled="is_pre_order" :current_company="autocomplete.companies" @onSelect="onCompanySelect" />
        </template>
        <template v-else>
          <SelectCompany :disabled="is_pre_order" @onSelect="onCompanySelect" />
        </template>
        <ValidationError :show="step2_is_submitted" :value="company_id" />
      </b-col>
    </b-row>

    <div class="row">
      <template v-if="autocomplete.products">
        <SelectProduct :current_product="autocomplete.products" :disabled="is_pre_order" :ratio="[4, 4, 4]"
          @onSelect="onProductSelected" />
      </template>
      <template v-else>
        <SelectProduct :disabled="is_pre_order" :ratio="[4, 4, 4]" @onSelect="onProductSelected" />
      </template>
      <div style="transform: translateY(-13px)">
        <ValidationError :show="step2_is_submitted" :value="product_id">
          <template v-slot:message>Product field is required !</template>
        </ValidationError>
      </div>
      <div class="col-12 mt-2">
        <label class="form-label">Comment</label>
        <textarea v-model="comment" class="form-control" placeholder="Comment section.." rows="5" />
      </div>
    </div>

    <div class="d-flex align-items-start gap-3 mt-3">
      <b-button type="button" @click="goPreviousStep()" variant="light" class="btn-label">
        <i class="
                          ri-arrow-left-line
                          label-icon
                          align-middle
                          fs-16
                          me-2
                        "></i>Back to Order Info (1)
      </b-button>
      <b-button variant="success" type="submit" class="btn-label right ms-auto">
        <i class="
                            ri-truck-line
                            label-icon
                            align-middle
                            fs-16
                            ms-2
                          ">
        </i>
        {{ perform === "create" ? "Proceed to Shipping" : "Submit & Update" }}
      </b-button>
    </div>
  </form>
</template>

<style scoped></style>