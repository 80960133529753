<script>
import {defineComponent} from 'vue'
import {orderComputed, ordersMehtods} from "@/state/helpers"
import axios from "axios"
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "Step4",
  emits: ['created', 'goNextStep', 'goPreviousStep'],
  data() {
    return {
      containerTypes: [
        {id: '20', name: '20'},
        {id: '20HC', name: '20HC'},
        {id: '40', name: '40'},
        {id: '40HC', name: '40HC'},
        {id: '45', name: '45'},
      ],
      taskTypes: [],
      task_type_id: null,
      task_deadline: null,
      create_started: false
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    ...orderComputed,
    autocomplete_order_number: {
      get() {
        return this.$store ?
            this.$store.state.orders.autocomplete.order_number ?
                this.$store.state.orders.autocomplete.order_number : null
            : null
      },
      set(value) {
        this.$store.state.orders.autocomplete.order_number = value
      }
    },
    container_types: {
      get() {
        return this.$store ? this.$store.state.orders.container_types : ''
      },
    },
    counterparties: {
      get() {
        return this.$store ? this.$store.state.orders.counterparties : ''
      },
    },
    request_status: {
      get() {
        return this.$store ? this.$store.state.orders.request_status : []
      },
      set(value) {
        this.setRequestStatus(value)
      }
    },
    pre_order: {
      get() {
        return this.$store ? this.$store.state.orders.pre_order : ''
      },
      set(value) {
        this.$store.state.orders.pre_order = value
      },
    },
    containerTypesWaitingForDelete() {
      return this.container_types.filter(container_type => container_type.delete_is_confirmed)
    },
    formIsInvalid() {
      let hasContainerTypes = this.container_types.length > 0
      let hasCounterparties = this.counterparties.length > 0
      let hasInvalidCategories = this.container_types.filter(container_type => {
        return container_type.quantity === '' ||
            container_type.agreed_rate === '' ||
            container_type.counterparties.filter(counterparty => {
              return counterparty.categories.filter(category => category.price === '').length > 0
            }).length > 0
      }).length > 0
      return hasContainerTypes && hasCounterparties && hasInvalidCategories
    },
  },
  methods: {
    ...ordersMehtods,
    selectOrUnselectCounterparty(id) {
      if (this.container_types.map(t => t.type).includes(id)) {
        this.removeContainerType(id)
      } else {
        this.addContainerType({
          type: id,
          quantity: '',
          agreed_rate: '',
          counterparties: []
        })
      }
    },
    deleteContainerType(container_type) {
      if (container_type.delete_is_confirmed) {
        this.removeContainerType(container_type.type)
      } else {
        container_type.delete_is_confirmed = true
      }
    },
    async createOrder() {
      let counterparties = []
      let applications = []

      this.$store.state.orders.counterparties.forEach(counterparty => {
        const counterpartyId = counterparty.counterparty.value;
        const categories = counterparty.categories;
        const territories = counterparty.territories ? counterparty.territories : [];

        categories.forEach(category => {
          const categoryId = category.value;
          counterparties.push({
            category_id: categoryId,
            counterparty_id: counterpartyId,
          });
        });

        if (this.pre_order && this.pre_order.type === 'code_order') {
          applications.push({
            territories: territories.map(t => {
              return {
                id: t.value,
                name: t.label
              }
            }),
            forwarder_id: counterparty.counterparty.value,
          });
        }
      });

      const date = new Date(this.$store.state.orders.date)

      let order = {
        "order": {
          "lot_number": this.$store.state.orders.lot_number,
          "date": date.toISOString().split("T")[0],
          "position": this.$store.state.orders.position,
          "type": this.$store.state.orders.order_type,
          "shipment_status": this.$store.state.orders.shipment_status,
          "payment_status": this.$store.state.orders.payment_status,
          "shipper": this.$store.state.orders.shipper,
          "consignee": this.$store.state.orders.consignee,
          "departure_id": this.$store.state.orders.departure_id,
          "destination_id": this.$store.state.orders.destination_id,
          "border_crossing": this.$store.state.orders.border_crossing,
          "conditions_of_carriage": this.$store.state.orders.conditions_of_carriage,
          "rolling_stock": this.$store.state.orders.rolling_stock,
          "departure_country": this.$store.state.orders.departure,
          "destination_country": this.$store.state.orders.destination,
          "comment": this.$store.state.orders.comment,
          "company_id": this.$store.state.orders.company_id,
          "counterparties": counterparties,
        },
        "sending_type": this.$store.state.orders.sending_type,
        "product_id": this.$store.state.orders.product_id,
        "container_types": this.$store.state.orders.container_types.map(type => {
          let container_preliminary_costs = []

          type.counterparties.forEach(counterparty => {
            const counterpartyId = counterparty.counterparty.value;
            const categories = counterparty.categories;

            categories.forEach(category => {
              const categoryId = category.value;
              container_preliminary_costs.push({
                category_id: categoryId,
                counterparty_id: counterpartyId,
                preliminary_cost: category.price
              });
            });
          });

          return {
            "agreed_rate": type.agreed_rate,
            "quantity": type.quantity,
            "container_type": type.type,
            "container_preliminary_costs": container_preliminary_costs
          }
        }),
        "process_header_id": this.task_type_id,
        "due_date": this.task_deadline,
        "inquiry_id": this.$route.query.inquiry_id,
      }

      if (this.pre_order && this.pre_order.type === 'code_order') {
        order.applications = applications
        order.pre_order_id = this.pre_order.id
      }

      try {
        this.request_status = 'creating'
        this.$store.state.orders.show_order_number = false
        let response = await axios.post(
            this.pre_order && this.pre_order.type === 'code_order'
                ? '/pre_order/convert_pre_order/container_order/'
                : '/container_order/create/new/',
            order)
        localStorage.setItem('ctr_order', response.data.order)
        if (this.pre_order.type === 'code_order') {
          this.pre_order.applications = response.data.applications ? response.data.applications : []
        }
        if (localStorage.getItem('autocompletes')) {
          let exists = JSON.parse(localStorage.getItem('autocompletes')).find(item => item === this.autocomplete_order_number)

          if (!exists) {
            localStorage.setItem('autocompletes',
                JSON.stringify(
                    [
                      this.autocomplete_order_number,
                      ...JSON.parse(localStorage.getItem('autocompletes')),
                    ]
                )
            )
          }
        } else {
          localStorage.setItem('autocompletes', JSON.stringify([this.autocomplete_order_number]))
        }
        this.request_status = 'success'
        this.$emit('created')
      } catch {
        this.request_status = 'error'
      }
    },
    async getTaskProductList() {
      try {
        let response = await axios.get('/task/product/list/')
        this.taskTypes = response.data.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
      } catch {
        alert("error")
        this.taskTypes = []
      }
    }
  },
  mounted() {
    this.getTaskProductList()
  }
})
</script>

<template>
  <div>
    <h5 class="mb-1">Counterparty Prices, {{ formIsValid }}</h5>
    <p class="text-muted mb-4">
      Please fill all information below
    </p>
  </div>

  <b-row gutter-x="4" class="mb-5" v-if="pre_order && pre_order.type === ''">
    <b-col lg="2" md="4" sm="6" v-for="(ctype) in containerTypes" :key="'c_type_' + ctype.id">
      <template
          v-if="(container_types.map(t=>t.type).includes(ctype.id) && container_types.length === 1) || containerTypesWaitingForDelete.length > 0">
        <div style="cursor: not-allowed"
             class="d-flex justify-content-between align-items-center cursor-pointer border rounded-3 p-3"
             :class="{'border-secondary': container_types.map(t=>t.type).includes(ctype.id)}">
          <h4 class="my-0">
          <span class="badge pt-2"
                :class="{
                      'badge-gradient-info': container_types.map(t=>t.type).includes(ctype.id),
                      'badge-soft-success': !container_types.map(t=>t.type).includes(ctype.id)
                }"
          >
            {{ ctype.name }}
          </span>
          </h4>
        </div>
      </template>
      <template v-else>
        <div @click="selectOrUnselectCounterparty(ctype.id)"
             class="d-flex justify-content-between align-items-center cursor-pointer border rounded-3 p-3"
             :class="{'border-secondary': container_types.map(t=>t.type).includes(ctype.id)}">
          <h4 class="my-0">
          <span class="badge pt-2"
                :class="{
                      'badge-gradient-info': container_types.map(t=>t.type).includes(ctype.id),
                      'badge-soft-success': !container_types.map(t=>t.type).includes(ctype.id)
                }"
          >
            {{ ctype.name }}
          </span>
          </h4>
        </div>
      </template>
    </b-col>
  </b-row>

  <TransitionGroup name="list" tag="ul" class="mb-4 p-0">
    <div v-for="type in container_types" :key="`list_` + type.container_type"
         class="border rounded-3 p-3 overflow-hidden my-2 mb-3"
         :class="{
              'border-secondary': !type.delete_is_confirmed,
              'border-danger': type.delete_is_confirmed,
         }"
    >
      <b-row class="mb-0" gutter-y="3">
        <b-col sm="12">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="my-0"> Container type:
              <span class="badge ms-2 align-middle"
                    :class="{
                      'badge-gradient-secondary': !type.delete_is_confirmed,
                      'badge-gradient-danger': type.delete_is_confirmed,
                 }">
                {{ type.type }}
              </span>
            </h5>
            <b-button :disabled="containerTypesWaitingForDelete.length > 0 || container_types.length === 1  "
                      @click="deleteContainerType(type)" v-if="!type.delete_is_confirmed"
                      variant="outline-danger" size="sm">Remove
            </b-button>
            <b-button-group v-else-if="type.delete_is_confirmed">
              <b-button @click="deleteContainerType(type)" variant="danger" size="sm">Confirm & Delete</b-button>
              <b-button @click="type.delete_is_confirmed = false" variant="light" size="sm">Cancel</b-button>
            </b-button-group>
          </div>
        </b-col>
        <b-col sm="12">
          <div class="d-flex justify-content-between gap-4">
            <div class="w-100">
              <label class="form-label">
                Quantity
                <span class="text-danger">*</span>
              </label>
              <input
                  v-if="pre_order && pre_order.type === ''"
                  :disabled="containerTypesWaitingForDelete.length > 0"
                  :style="{cursor: containerTypesWaitingForDelete.length > 0 ? 'not-allowed' : ''}"
                  v-model="type.quantity" type="number" class="form-control" placeholder="Quantity">

              <input
                  v-if="pre_order && pre_order.type === 'code_order'"
                  readonly
                  :style="{cursor: containerTypesWaitingForDelete.length > 0 ? 'not-allowed' : ''}"
                  :value="type.quantity" type="number" class="form-control" placeholder="Quantity">

            </div>
            <div class="w-100">
              <label class="form-label">
                Agreed rate
                <span class="text-danger">*</span>
              </label>
              <input :disabled="containerTypesWaitingForDelete.length > 0"
                     :style="{cursor: containerTypesWaitingForDelete.length > 0 ? 'not-allowed' : ''}"
                     v-model="type.agreed_rate" type="number" class="form-control" placeholder="Agreed rate">
            </div>
          </div>
        </b-col>

        <b-col lg="4" v-for="counterparty in type.counterparties.filter(c=>c.counterparty!==null)"
               :key="counterparty">
          <div class="border rounded-3 p-3">
            <h5 class="mb-4">{{ counterparty.counterparty.label }}</h5>
            <b-row gutter-y="3">
              <b-col sm="12" v-for="category in counterparty.categories" :key="category">
                <label class="form-label">
                  {{ category.label }}
                  <span class="text-danger">*</span>
                </label>
                <input v-model="category.price" type="number" class="form-control"
                       :placeholder="`Price for ${category.label}`"
                       :disabled="containerTypesWaitingForDelete.length > 0"
                       :style="{cursor: containerTypesWaitingForDelete.length > 0 ? 'not-allowed' : ''}"

                >
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </TransitionGroup>

  <b-row class="align-items-center justify-content-between">
    <b-col lg="6">
      <label class="form-label">Select template task <span class="text-muted">(optional)</span> </label>
      <Multiselect v-model="task_type_id" :options="taskTypes"/>
    </b-col>
    <b-col lg="6">
      <label class="form-label">Deadline </label>
      <input v-model="task_deadline" type="date" class="form-control">
    </b-col>
  </b-row>

  <div class="text-end mt-4">
    <b-button v-if="!creating_order"
              @click="createOrder()" :disabled="formIsInvalid"
              variant="success" class="fs-18 w-100">Create New Order
    </b-button>
    <button v-else type="button" disabled class="btn btn-success btn-load fs-18 w-100">
      Creating New Order...
      <b-spinner class="ms-2"></b-spinner>
    </button>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>